import { default as Card } from './Card';
import Image from './Image';
import Body from './Body';
import Headline from './Headline';
import Description from './Description';
import Copy from './Copy';
import Accessories from './Accessories';
import AccessoriesRow from './AccessoriesRow';
import Label from './Label';
import Action from './Action';
import Logo from './Logo';
import Author from './Author';
Card.displayName = "CardBase";
Body.displayName = "Card.Body";
Image.displayName = "Card.Image";
Headline.displayName = "Card.Headline";
Description.displayName = "Card.Description";
Copy.displayName = "Card.Copy";
Accessories.displayName = "Card.Accessories";
AccessoriesRow.displayName = "Card.AccessoriesRow";
Label.displayName = "Card.Label";
Action.displayName = "Card.Action";
Author.displayName = "Card.Author";
export { Card as default, Body, Image, Headline, Description, Copy, Accessories, AccessoriesRow, Label, Action, Logo, Author };