import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["src", "alt", "size", "contentfulLoader"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import contentfulImageLoader from '@segment/utils/contentfulImageLoader/contentfulImageLoader';
import * as views from './Author.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var Author = function Author(_ref) {
  var src = _ref.src,
      alt = _ref.alt,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "medium" : _ref$size,
      contentfulLoader = _ref.contentfulLoader,
      rest = _objectWithoutProperties(_ref, _excluded);

  return _jsx(views.Author, _objectSpread({
    loader: contentfulLoader ? contentfulImageLoader : undefined,
    src: src,
    alt: alt,
    width: size === "medium" ? "40" : "32",
    height: size === "medium" ? "40" : "32",
    size: size
  }, rest));
};
export default Author;