import * as views from './Action.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var Action = function Action(_ref) {
  var children = _ref.children,
      _ref$alignment = _ref.alignment,
      alignment = _ref$alignment === void 0 ? "left" : _ref$alignment;
  return _jsx(views.Action, {
    alignment: alignment,
    children: children
  });
};
export default Action;