import * as views from './AccessoriesRow.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var AccessoriesRow = function AccessoriesRow(_ref) {
  var children = _ref.children;
  return _jsx(views.AccessoriesRow, {
    children: children
  });
};

export default AccessoriesRow;