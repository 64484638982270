import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["src", "alt", "tokens"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './Logo.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var LogoBase = function LogoBase(_ref) {
  var src = _ref.src,
      alt = _ref.alt,
      tokens = _ref.tokens,
      rest = _objectWithoutProperties(_ref, _excluded);

  return _jsx(views.Logo, _objectSpread(_objectSpread(_objectSpread({
    src: src,
    alt: alt
  }, tokens), rest), {}, {
    width: "32",
    height: "32"
  }));
};

export var Logo = withDesignTokens(LogoBase, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      borderColor: theme.palette.brand.darkAcai[700]
    };
  },
  dark: function dark(theme) {
    return {
      borderColor: theme.palette.brand.darkAcai[700]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      borderColor: theme.palette.twilio.gray[100]
    };
  }
});
export default Logo;