import * as views from './Accessories.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var Accessories = function Accessories(_ref) {
  var children = _ref.children;
  return _jsx(views.Accessories, {
    children: children
  });
};

export default Accessories;