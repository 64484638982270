import * as views from './Copy.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var Copy = function Copy(_ref) {
  var children = _ref.children;
  return _jsx(views.Copy, {
    children: children
  });
};

export default Copy;